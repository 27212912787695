import master_class_img_1 from "../../images/Deeper-dive-marlene-mcneese-profile.png";
import master_class_img_1_mobile from "../../images/Deeper-dive-marlene-mcneese-profile-mobile.png";

const fullName = "";

const copyListMasterClass = [
    {
        type: "text",
        content: "<h2 data-jump-destination=\"pioneering-science\" class=\"title\">Progress and Challenges in HELPING END the HIV Epidemic</h2><div class=\"px-0 container\"><div class=\"row\"><div class=\"col-12\"><p>This EHE Masterclass—part of our <i>Blueprints for Success Training Institute</i> series—focuses on the extensive progress that has been made toward helping end the HIV epidemic, challenges that remain, and strategies to overcome these hurdles. Presenter Dr Oni Blackstock discusses global- and national-level goals toward helping end the HIV epidemic, and presenter Greg Millett examines regional and demographic HIV hotspots and how health disparity can act as barriers. To conclude the masterclass, Dr Patrick Sullivan moderates an engaging conversation between these two HIV leaders on ways to continue making progress.</p></div></div></div>"
    }
]


export default copyListMasterClass;
