import * as React from "react";
import { Component } from 'react';
import Layout from "../../components/global/layout.js";
import Content from '../../components/ehe-masterclass/content.js'


class EheMasterclass extends Component {
  render() {
    return (
      <>
        <Layout section="deeper-dive" page="ehe-masterclass">
          <Content section="deeper-dive" page="ehe-masterclass"/>
        </Layout>
      </>
    )
  }
}

export default EheMasterclass;