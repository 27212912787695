// import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from "./copyList";
import References from "../global/references.js";

import * as React from "react";
import { Component } from "react";

export default class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <ArticleLayout
          page={this.props.page}
          copyList={copyList}
          ariaLabel="Group of people standing shoulders locked outside in a city"
          videoStillAlt="Click to play the EHE Masterclass Video"
          subheading="Ending the HIV Epidemic (EHE) Masterclass"
          heading="If Not Now, When? Sustaining Urgency in Helping End the HIV Epidemic"
        />
        <References page={this.props.page} />
      </div>
    );
  }
}
